export const MOUSE_DOWN_EVENT = 'mousedown';
export const KEY_DOWN_EVENT = 'keydown';
export const KEY_UP_EVENT = 'keyup';
export const KEY_BACKQUOTE_NAME = 'Backquote';

export const ERROR_TYPE_PASSWORD = 'password';

export const FILLETS_PAGE = 'fillets';
export const COLOR_PAGE = 'color';
export const LAYOUT_PAGE = 'layout';
export const TEXT_PAGE = 'text';
export const FIELDS_TAB = 'fields';
export const INPUT_PAGE = 'input';
export const TOKENS_PAGE = 'tokens';
export const PASSWORD_PAGE = 'password';
export const ACTIONS_TAB = 'actions';
export const BUTTON_PAGE = 'button';
export const MEATBALLS_PAGE = 'meatballs';
export const NAVIGATION_TAB = 'navigation';
export const TABBAR_PAGE = 'tabbar';
export const TABS_PAGE = 'tabs';
export const PAGINATION_PAGE = 'pagination';
export const CHOICES_TAB = 'choices';
export const CHECKBOX_PAGE = 'checkbox';
export const SELECT_PAGE = 'select';
export const CALENDAR_PAGE = 'calendar';
export const POPUPS_TAB = 'popups';
export const HINT_PAGE = 'hint';
export const MODAL_PAGE = 'modal';
export const FORMS_TAB = 'forms';
export const USAGE_PAGE = 'usage';
export const VALIDATION_PAGE = 'validation';
export const LIBRARY_PAGE = 'library';
export const LOGO_PAGE = 'logo';
export const ICONS_PAGE = 'icons';
export const TOUCH_AREAS_PAGE = 'touchAreas';
export const SCREENS_TAB = 'screens';
export const HEADER_PAGE = 'header';

export const PRINCIPES_TAB = 'principes';

export const NAVIGATION_TAB_NAME = 'name';
export const NAVIGATION_TAB_SIZE = 'size';
export const NAVIGATION_TAB_TYPE = 'type';
export const NAVIGATION_TAB_DESIGN = 'design';
export const NAVIGATION_TAB_PRINCIPES = 'principes';
export const NAVIGATION_TAB_ERRORS = 'errors';
export const NAVIGATION_TAB_BLOCK = 'block';
export const NAVIGATION_TAB_MODAL = 'modal';

export const COMPONENTS_BLOCK = 'components';
export const SOURCES_BLOCK = 'sources';

export const TABLET_WIDTH = 1024;

export const COMMIT_FRONTEND = '5d691095b5fc595e7c59aaf5a6f03515d3ec9b69';
export const COMMIT_BACKEND = '79c2906cd84e65c536f20a5fe105c4838993f9f0';

export const NON_DIGITS_REG_EXP = /\D/g;
